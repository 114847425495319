<template>
  <div>
    <form
      novalidate
      class="reset-password-form md-layout"
      @submit.prevent="resetPassword"
    >
      <md-card
        class="md-layout-item md-large-size-40 md-size-50 md-small-size-60 md-xsmall-size-100 container"
      >
        <md-card-header>
          <div class="md-title title-color">Reset Password</div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('password')">
              <label for="password">New Password</label>
              <md-input
                type="password"
                name="password"
                @blur="toggleFocusPassword"
                @focus="toggleFocusPassword"
                id="password"
                v-model="model.password"
                :disabled="isSubmitting"
              />
              <span class="md-error" v-if="passwordValidationError">{{
                passwordValidationError
              }}</span>
            </md-field>
          </div>

          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('confirmPassword')">
              <label for="password">Confirm Password</label>
              <md-input
                type="password"
                name="confirm-password"
                id="confirm-password"
                v-model="model.confirmPassword"
                :disabled="isSubmitting"
              />
              <span class="md-error" v-if="confirmPasswordValidationError">{{
                confirmPasswordValidationError
              }}</span>
            </md-field>
          </div>

          <div
            v-if="showPasswordPolicy"
            class="password-policy password-policy-container"
          >
            <password-policy :password="model.password" />
          </div>
          <password-strength-bar 
            :password="model.password" 
            v-if="model.password"
          />
        </md-card-content>

        <md-card-actions>
          <md-button
            id="reset"
            type="submit"
            class="submit-button"
            :disabled="isSubmitting"
            >Reset Password</md-button
          >
        </md-card-actions>
      </md-card>
    </form>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import validationMixin from "../../validation/validation_mixin";
import { required, sameAs, email, maxLength } from "vuelidate/lib/validators";
import notification from "../../notification";
import restAdapter from "../../restAdapter";
import PasswordPolicy from "../../components/base/PasswordPolicy.vue";
import PasswordStrengthBar from '@/components/PasswordStrengthBar.vue';

const validatePassword = (value) => {
  if (typeof value === "undefined" || value === null || value === "") {
    return true;
  }

  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/.test(value);
};

export default {
  name: "resetPassword",
  mixins: [validationMixin],
  components: { PasswordPolicy, PasswordStrengthBar, },
  mounted() {
    if (this.$route.query.email) {
      this.model.email = this.$route.query.email;
    }
  },
  data: () => ({
    model: {
      email: null,
      password: null,
      confirmPassword: null,
    },
    isSubmitting: false,
    showPasswordPolicy: false,
  }),
  validations: {
    model: {
      password: {
        required,
        validatePassword,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  computed: {
    emailValidationError() {
      if (!this.$v.model.email.required) {
        return "The email is required";
      }

      if (!this.$v.model.email.email) {
        return "Invalid email";
      }

      if (!this.$v.model.email.maxlength) {
        return "Email should less than 255 characters";
      }

      return null;
    },
    passwordValidationError() {
      if (!this.$v.model.password.required) {
        return "The password is required";
      }

      if (!this.$v.model.password.validatePassword) {
        return "Please add a strong password";
      }

      return null;
    },
    confirmPasswordValidationError() {
      if (!this.$v.model.confirmPassword.required) {
        return "The confirm password is required";
      }

      if (!this.$v.model.confirmPassword.sameAsPassword) {
        return "Password and confirm password should be identical";
      }

      return null;
    },
  },
  methods: {
    clearForm() {
      this.$v.$reset();
      this.model.email = null;
      this.model.password = null;
    },
    resetPassword() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.isSubmitting = true;

      const credentials = {
        email: this.model.email,
        password: this.model.password,
        confirm_password: this.model.confirmPassword,
        token: this.$route.query.token,
      };

      restAdapter
        .post("/api/reset_password/", credentials)
        .then((response) => {
          this.isSubmitting = false;
          notification.success("Password has been reset.");
          this.$router.push({ name: "Login" });
        })
        .catch((error) => {
          this.isSubmitting = false;
          if (
            error.response &&
            (error.response.status == 400 || error.response.status == 401)
          ) {
            notification.errors({ emailPassword: ["Invalid email."] });
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    toggleFocusPassword() {
      if (!this.showPasswordPolicy) {
        this.showPasswordPolicy = true;
        return;
      }

      this.showPasswordPolicy = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password-form {
  padding-top: 100px;
  justify-content: center;
}

.container {
  background: #ffffff;
  box-shadow: 0px 13.5045px 25.8836px rgba(61, 77, 135, 0.1);
  border-radius: 7px;
}

.title-color {
  width: fit-content;
  color: #442d65 !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 116.1%;
}

.submit-button {
  width: 140px;
  height: 35px;
  border: 1px solid #473068;
  background: #473068 !important;
  color: $paginateText !important;
  border-radius: 3px !important;
  margin-right: 10px !important;
}

.md-field.md-theme-default.md-focused label {
  color: #473068 !important;
}

.md-field.md-theme-default:before {
  background-color: #473068 !important;
}

.password-policy-container {
  margin-top: 30px;
  background: rgba(71, 48, 104, 0.05);
  border: 0.5px solid #473068;
  border-radius: 3px;
}

.password-policy-title {
  text-align: left;
  margin: 15px 0px 10px -15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #473068;
}

.password-policy__items {
  margin-bottom: 15px;
}

.policy-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #473068;
}

.password-policy-icon {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
